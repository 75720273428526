.bottom-left, .bottom-right, .bottom-middle, .center {
    cursor: initial;    
    background: #34383c;
    border-radius: 4px;
    display: inline-block;
    width: max-content;
    height: max-content;
    padding: 10px 45px 12px 17px;
    -webkit-box-shadow: -0.5vmax 0.5vmax 0.5vmax 0vmax rgba(0,0,0,0.44);
    -moz-box-shadow: -0.5vmax 0.5vmax 0.5vmax 0vmax rgba(0,0,0,0.44);
    box-shadow: -0.5vmax 0.5vmax 0.5vmax 0vmax rgba(0,0,0,0.44);
}

.bottom-right {
    position: absolute;
    top: 46px;
    right: 4px;
    z-index: 1000;
}

.bottom-left {
    position: absolute;
    display: flex;    
    top: 45px;
    left: 60px;
    z-index: 1000;
}

.center {
    position: absolute;    
    left: 0;
    right: 0;    
    margin: 0 auto !important;
    z-index: 1000;
    top: 150px;
    max-width: 680px;
    min-width: 680px;
}

.bottom-left:after, .bottom-right:after, .bottom-middle:after {
	content: '';
	position: absolute;
	top: 0;
	width: 0;
	height: 0;
	border: 0.5vmax solid transparent;
	border-bottom-color: #34383c;
	border-top: 0;
	margin-left: -0.5vmax;
    margin-top: -0.5vmax;
}

.bottom-left:after {
	left: 15%;	
}

.bottom-right:after {
	right: 6%;
}

.bottom-middle:after {
	left: 50%;
}