.secondary-bar {  
    position: absolute;
    width: 100%;
    min-width: 1120px;
    height: 38px;
    top: 40px;
    z-index: 10;
}

.left-seperator { 
    min-width: 1px;
    width: 1px;
    height: 24px;    
    margin-top: 8px;
}

.right-seperator {
    min-width: 1px;
    width: 1px;
    height: 24px;
    margin-top: 8px;
    position: absolute;
    right: 112px;  
}

.bar-right-side {
    right: 0;
    display: flex;
    position: absolute;
    width: 26vw;
    float: left;
}

.bar-left-side {
    left: 0;
    display: flex;
    float: left;
}