html,body,button {
    height: 100%;
    
    font-family: noto-sans-display !important;
}

body {
    min-width:1020px;
}

/* fonts */
@font-face {
    font-family: Noto-Sans-Display;
    src:url('../src/assets/fonts/NotoSansDisplay-Regular.ttf');
}

@font-face {
    font-family: Noto-Sans-Display-Bold;
    src:url('../src/assets/fonts/NotoSansDisplay-Bold.ttf');
}

@font-face {
    font-family: Noto-Sans-Display-ExtraLight;
    src:url('../src/assets/fonts/NotoSansDisplay-ExtraLight.ttf');
}

@font-face {
    font-family: Noto-Sans-Display-Light;
    src:url('../src/assets/fonts/NotoSansDisplay-Light.ttf');
}

@font-face {
    font-family: Noto-Sans-Display-Medium;
    src:url('../src/assets/fonts/NotoSansDisplay-Medium.ttf');
}

@font-face {
    font-family: Noto-Sans-Display-SemiBold;
    src:url('../src/assets/fonts/NotoSansDisplay-SemiBold.ttf');
}

@font-face {
    font-family: Noto-Sans-Display-Thin;
    src:url('../src/assets/fonts/NotoSansDisplay-Thin.ttf');
}

/* colors palette */
.palette-orenge {
    color: #FF7F00; 
}

.palette-bg-orenge {
    background-color: #FF7F00;
}

.palette-br-orenge {
    border: 1px solid #FF7F00;
}

.palette-red {
    color: #E11414;
}

.palette-bg-red {
    background-color: #E11414;
}

.palette-br-red {
    border: 1px solid #E11414 !important;
}

.palette-yellow {
    color: #FFC800;
}

.palette-bg-yellow {
    background-color: #FFC800;
}

.palette-green {
    color: #008C46;
}

.palette-purple {
    color: #7F00FF;
}

.palette-br-purple {
    border: 1px solid #7F00FF;
}

.palette-light-green {
    color: #00FF7F;
}

.palette-bg-light-green {
    background-color: #00FF7F;
}

.c-0064FF {
    color: #0064FF;
}

.br-0064FF {
    border: 1px solid #0064FF;
}

.c-83E9FF {
    color: #83E9FF;
}

.c-6FD5FF {
    color: #6FD5FF;
}

.c-5BC1FF {
    color: #5BC1FF;
}

.br-5BC1FF {
    border: 1px solid #5BC1FF;
}

.c-47ADFF {
    color: #47ADFF;
}

.bg-3399FF {
    background-color: #3399FF;
}

.c-0085EB {
    color: #0085EB;
}

.c-0071D7 {
    color: #0071D7;
}

.c-005DC3 {
    color: #005DC3;
}

.c-0049AF {
    color: #0049AF;
}

.c-02060A {
    color: #02060A;
}

.bg-02060A {
    background-color: #02060A;
}

.bg-161A1E {
    background-color: #161A1E;
}

.bg-2A2E32 {
    background-color: #2A2E32;
}

.c-34383C {
    color: #34383C;
}

.bg-34383C {
    background-color: #34383C;
}

.c-3E4246 {
    color: #3E4246;
}

.c-52565A {
    color: #52565A;
}

.bg-52565A {
    background-color: #52565A;
}

.c-666A6E {
    color: #666A6E;
}

.bg-666A6E {
    background-color: #666A6E;
}

.c-7A7E82 {
    color: #7A7E82;
}

.br-7A7E82 {
    border: 1px solid #7A7E82;
}

.c-8E9296 {
    color: #8E9296;
}

.c-989CA0 {
    color: #989CA0;
}

.c-A2A6AA {
    color: #A2A6AA;
}

.c-B6BABE {
    color: #B6BABE;
}

.c-CACED2 {
    color: #CACED2;
}

.c-DEE2E6 {
    color: #DEE2E6;
}

.c-F2F6FA {
    color: #F2F6FA;
}

.bg-transparent {
    background-color: transparent;
}

/* scrollbar */
::-webkit-scrollbar {
    width: 6px;
    left:-2px;
}
  
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent; 
    border-radius: 10px;
    margin: 11px;
}

::-webkit-scrollbar-thumb {
    background: #2A2E32;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background: #52565A; 
}