.time-filter-popup {
    height: inherit;
    display: flex;
    align-items: center;    
    display: grid;
    padding: 0 !important;   
}

.filter-button {
    color: #A9ACB1; 
    background-color: transparent;
    border: none;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    padding: 8px 0px
}

.filter-button button:hover {
    color: white;
}

.filter-button button:focus {
    border-color: transparent;
    background-color: transparent;
    color: white;
    outline: none;
}

.filter-button button:active {
    background-color: transparent;
    border-color: transparent;
    color: white;
}

.v-icon {
    background-image: url('../../../../../../assets/images/v-icon.svg');
    background-repeat: no-repeat;    
    visibility: hidden;
    width: 15px;
    margin-top: 14px;
    margin-right: 11px;
}

.filter-label {
    display: flex;
}

.visible {
    visibility: visible;
    color: white; 
}
