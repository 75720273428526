.incident-alert-list {   
    position: absolute;
    width: 184px;
    max-height: 538px;
    left: 70px;
    top: 184px;
    border-radius: 4px;
    z-index: 9;
    background:rgba(0, 0, 0, 0.3);
    overflow-y: scroll; 
}

.incident-alert-popup-container {
    position: relative;
    top: 184px;
    left: 260px;
    z-index: 10;
    display: inline-flex;
}