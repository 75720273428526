.incident-alert-list-item {
    width: 152px;
    height: 56px;
    border-radius: 4px;
    margin: 10px 17px;
    opacity: 1 !important;
    display: flex;
    cursor: pointer;
}

.incident-alert-list-item:hover {
    background-color: #444444 !important;
}

.priority-mark {
    width: 8px;
    height: 56px;
    border-radius: 4px 0px 0px 4px;
}

.incident-icon {
    margin: 8px 13px;
    width: 40px;
    text-align: center;
}

.time-label {
    font-size: 13px;
    align-items: center;
}

.range-label {
    font-size: 13px;
    align-items: center;
}

.time-range-group {
    margin: 6px;
    text-align: center;
}

.direction-label {
    font-size: 13px;
    margin: auto;
}

.low {
    background-color: #FFC800;
}

.medium {
    background-color: #FF7F00;
}

.high {
    background-color: #E11414;
}