.dashboard {
    height: 100%; 
    flex-flow: column;
    display: flex;
}

.horizontal-seperator {
    position: absolute;
    width: 100%;
    height: 2px;  
}

.first {
    top: 38px;
}

.second {
    top: 78px;
}

.third {
    top: 148px;
    z-index: 6;
}

.four {
    bottom: 160px;
}

.vertical-seperator {
    position: absolute;
    width: 2px;
    height: 67%;  
    top: 150px;
}

.left {
    left: 62px;
}

.right {
    right: 64px;
}