.incident-priority-filter-button {
    height: 32px;
    width: 32px;
    margin: 8px 0px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}
.incident-priority-filter-button:last-of-type {
    margin-top: 16px;
}

.priority-indicator {
    height: 18px;
    width: 6px;
    margin: 0 auto;   
}

.filter-priority-all {
    background-image: linear-gradient(#E11414 0%, #E11414 33%,#FF7F00 33%, #FF7F00 66%, #FFC800 66%,#FFC800 100%);
}