.historical-data-popup {
    width: 654px;
    height: 520px;
    position: relative;
}

.historical-data-label {
    font-size: 27px;
    color: #CED2D6;
    display: flex;
    margin: 0px 0px 0px 41px;
    position: absolute;
    top: 3%;
}

.select-range-label {
    color: #A2A5AA;
    display: flex;
    font-size: 19px;
    margin: 23px 0px 0px 43px;
    position: absolute;
    top: 50px;
}

.from-row {
    position: absolute;
    top: 133px;
}

.from-label {
    font-size: 19px;
    display: flex;
    float: left;
    margin: 0px 11px 0px 43px;
    color: #A2A5AA;
}

.historical-input {
    background: transparent;
    font-size: 17px;
    display: flex;
    color: #CED2D6 !important;
    border: 1px solid #CED2D6;
    padding: 0 2px;
    text-align: center;
    letter-spacing: 1px;
}

.historical-input::placeholder {
    color: #CED2D6;
}

.date {
    margin-right: 9px;
    float: left;
    width: 119px;
}

.hour {
    width: 65px;
}

.to-row {
    position: absolute;
    top: 172px;
}

.to-label {
    font-size: 19px;
    display: flex;
    float: left;
    margin: 0px 6px 0px 43px;
    color: #A2A5AA;
}

.to-label-content {
    font-size: 16px;
    display: flex;
    float: left;
    margin: 3px 0px 0px 34px;
    color: #A2A5AA;
}

.date-categories {
    color: #A2A5AA;
    font-size: 15.5px;
    display: flex;
    margin: 19px 0px 0px 102px;
    position: absolute;
    top: 93px
}

.close-button {
    cursor: pointer;
    grid-column-start: 3;
    background-image: url('../../../../../../../assets/images/close-outline.svg');
    background-repeat: no-repeat;
    width: 2vw;
    margin-left: 0.5vw;
    height: 4vh;
    right: 0;
    position: absolute;
}

.historical-button {
    color: #CED2D6;
    background-color: #52565A !important;
    border-radius: .2vmax;
    height: 35px;
    width: 94px;
    border: none;
    font-size: 16px;
    position: absolute;
    letter-spacing: 1.5px;
}

.historical-button:hover {
    background-color: #A2A5AA !important;
}

.historical-button:active {
    background-color: #A2A5AA !important;
}

.select {
    top: 210px;
    left: 202px;
}

.apply {
    top: 450px;
    left: 508px;
}

.apply:disabled {
    background-color: #A2A5AA !important;
    color: #CED2D6 !important;
}

.calender-date-range {
    background: transparent !important;
    position: absolute;
    color: white !important;
    left: 343px;
    top: 95px;
}


/* override DateRange classes for more responsivity */

.rdr-Calendar {
    background: none !important;
    color: white !important;
}

.rdrInRange {
    background: #0049AF !important;
}

.rdr-Day {
    pointer-events: none !important;
    font-size: 15px !important;
    color: #CED2D6 !important;
}

.rdr-Days span {
    pointer-events: none !important;
}

.rdr-WeekDay {
    color: #7D7F82 !important;
    font-size: 15px !important;
}

.rdr-MonthAndYear-month,
.rdr-MonthAndYear-year {
    font-size: 15px !important;
    color: #CED2D6 !important;
}

.rdr-MonthAndYear-year {
    margin-left: 6px !important;
}

.rdr-MonthAndYear-divider {
    display: none !important;
}

.rdr-WeekDays {
    margin-bottom: 5px !important;
}

.is-inRange.is-selected::before {
    background-color: #0049AF !important;
    content: "";
    width: 35%;
    height: 55%;
    left: 3px;
    position: absolute;
    z-index: -12;
    margin: 4.5px 15px 0px 13px;
    padding: 13.6px 10px;
}

.is-selected::before {
    background-color: #0049AF !important;
    content: "";
    width: 28%;
    height: 55%;
    right: 14px;
    position: absolute;
    z-index: -12;
    margin: 4.5px 3px 0px 13px;
    padding: 13.6px 11px;
}

.is-selected::after {
    background-color: #3399FF !important;
    border-radius: 24vmax;
    content: "";
    width: 75%;
    height: 75%;
    left: 3px;
    top: 3.3px;
    position: absolute;
    z-index: -1;
    margin: 1px;
}

.is-selected {
    background: transparent !important;
    border-radius: 0 12vmax 12vmax 0;
    position: relative;
    z-index: 10;
}

.is-inRange {
    background: transparent !important;
    border-radius: 12vmax 0 0 12vmax;
}

.prev {
    background: transparent !important;
}

.prev i {
    border-color: transparent rgb(254, 254, 254) transparent transparent !important;
}

.next {
    background: transparent !important;
}

.next i {
    border-color: transparent transparent transparent rgb(254, 254, 254) !important;
}