.incident-alert-overview {
    width: 1827px;
    min-width: 1827px;
    display: grid;
    top: 83px;
    position: absolute;
    height: 60px;
    left: 64px;
}

.elements-margin-right {
    margin-right: 2vw;
}