.incident-alert-category-button,
.incident-alert-category-button-selected {
    width: 200px;
    height: 56px;
    background-color: #2A2E32;
    border-radius: 0.2vmax;
    border-width: 1px;
    display: flex;
    pointer-events: none; /*TODO: in this version the click not available */  
}

.cursor-active {
    cursor: pointer;
}

.cursor-not-active {
    cursor: auto;
}

.incident-alert-category-button-selected {
    background-color: #7F00FF !important;
}

.filter-title {
    margin: 14px 0 0 8px;
    font-size: 15px;
}

.filter-icon {
    display: flex;
    margin-left: 5px;
}

.alert-ellipse { 
    min-width: 24px;
    height: 24px;
    border-radius: 20px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: 8px;
    margin-top: 8px;
}

.visible {
    visibility: visible;
}

.hidden {
    visibility: hidden;
}

.alerts-count {
    display: inline-block;
    position: relative;
    margin: 0px 5px;
}