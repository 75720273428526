.location-filter {
    background: transparent;
    display: flex;    
    padding-top: 0.75vh;
    margin-left: 18px;
    padding-top: 8px;
    min-width: 650px;
}

.location-label {
    font-size: 16px;
    padding-left: 6px;
    padding-right: 6px;
    height: 30px;
    text-align: center;
}

.next-sign {
    margin: -2px 5px;
}