#container {	
	margin: 0 auto;
}

#controls {
    margin: 0 auto;
    text-align: center;
}

.timelineStyle {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 160px;
}

.timeline-button {
    height: 32px;
    width: 32px;
    bottom: 121px;
    position: absolute;
    border-radius: 4px;    
    border-color: transparent;
}

.play-pause-button {
    left: 0;
    right: 0;
    margin: 0 auto;
}

.play {
    background-image: url('/images/play-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.pause {
    background-image: url('/images/pause-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.change-timeline-values-button {
    left: 64px;
    background-image: url('/images/change-timeline-values-icon.svg');
    background-repeat: no-repeat;
    background-position: center; 
}

.visible {
    visibility: visible;
}

.hidden {
    visibility: hidden;
}

.incident-popup-container {
    position: absolute;
    bottom: 170px;
    z-index: 11;
    display: inline-flex;
}