.main-navigation{
    height: 100%;
}

.main-navigation-bar {   
    position: absolute;
    width: 100%;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    left: 0px;
    top: 0px;
    background: #000000;
    min-width: 1120px;
    z-index: 10;
}

.content {
    background: #A2A6AA;    
    height: 100%;
}

.clickable-navbar-button{
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.back {
    background-image: url("../../assets/images/back-icon.svg");
    background-repeat: no-repeat;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    width: 20px;
    margin: 12px 25px;    
}

.version-label {
    font-size: 17px;
    min-width: 30px;
    align-self: center;
    position: absolute;
    left: 50%;
    margin-top: 8px;
}

.logo {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 0 auto;
    padding-right: 22%;
}

.logo img{
    height: 2.5vh;
    width: 8vw;
    padding-top: 2%;
}

.user-profile-btn {
    background-image: url('../../assets/images/user-profile-icon.svg');
    background-repeat: no-repeat; 
    position: absolute;
    right: 21px;
    height: 26px;
    width: 29px;
    top: 8px;
}






