.incident-alert-priority-filter {    
    position: absolute;
    top: 200px;        
    left: 16px;
    height: 200px;
    width: 70px;
}

.priority-seperator {
    height: 1px;
    width: 32px;
    background-color: #666A6E;
    position: absolute;
    top: 167.5px;
}