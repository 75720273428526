@media all and (min-width: 480px) {
  .Login {
    width: 100%;
    height: 100%;
    background-image: url('/images/login-background.svg');
    background-size: contain;
  }
  
  .Login form {
    position: relative;
    font-family: Noto-Sans-Display-Light;
    right: 125px;
    float: right;
    top: 47.5%;
    width: 248px;
  }

  .Login form label {
    font-size: 24px;
    font-weight: lighter;
    color: #FFFFFF;
    margin-bottom: 28px;
  }

  .Login form button {
    position: relative;
    width: 120px;
    height: 40px;
    left: 124px;
    top: 0px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 12px;
  }

  .Login form button img {
    padding-left: 10px;
  }

  .form-control {
    color: #B6BABE;
    background: #34383C;
    border-radius: 4px;
    border: 0px solid #3399FF;
  }

  .form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 1);
    color: #FFFFFF;
    background: #52565A;
    border: 1px solid #3399FF;
    border-radius: 4px;
  }

  input:focus ~ .floating-label,
  input:not(:focus):valid ~ .floating-label{
    top: -44px;
    left: 12px;
    font-size: 11px;
    opacity: 1;
  }

  .floating-label {
    position: relative;
    pointer-events: none;
    left: 12px;
    bottom: 30px;
    transition: 0.2s ease all;
    color: #B6BABE;
  }

  .custom-checkbox {
    position: relative;
    width: 120px;
    height: 40px;
    left: 124px;
    top: 20px;
  }

  .custom-control-label {
    font-size: 12px !important;
    padding-top: 5px;
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0px solid #52565A;
    -webkit-text-fill-color: #B6BABE !important;
    box-shadow: 0 0 0 100px #52565A inset !important;
    transition: 1s ease all;
  }

  input:-webkit-autofill ~ .floating-label,
  input:-webkit-autofill:hover ~ .floating-label, 
  input:-webkit-autofill:focus ~ .floating-label,
  textarea:-webkit-autofill ~ .floating-label,
  textarea:-webkit-autofill:hover ~ .floating-label,
  textarea:-webkit-autofill:focus ~ .floating-label,
  select:-webkit-autofill ~ .floating-label,
  select:-webkit-autofill:hover ~ .floating-label,
  select:-webkit-autofill:focus ~ .floating-label {
    top: -44px;
    left: 12px;
    font-size: 11px;
    opacity: 1;
    
  }

  .error-message {
    background-color: #D0021B;
    font-size: 11px !important;
    height: 47px !important;
  }
  
}

.forget-password-button {
  color: white;
  font-size: 12px;
  cursor: pointer;
  width: fit-content;
  position: relative;
  left: 125px;
}

.forget-password-button:focus {
  outline: 0;
}

.hide-note {
  visibility: hidden;
}

.show-note {
  visibility: visible;
}

.forget-password-note {
  font-size: 12px;
  background: #2D3235;
  color: #929599;
  border-radius: 4px;
  padding: 5px;
  position: relative;
  bottom: 17px;
}

.forget-password-note .note-title {
  color: #A9ABAF;
  font-weight: bold;
}

.close-note-popup {
  cursor: pointer;
  background-image: url('../../assets/images/close-outline.svg');
  background-color: #14181C;
  background-repeat: no-repeat;          
  top: 3px;
  width: 17px;
  margin: 0px 3px 0px 0px;
  height: 18px;
  right: 0;
  position: absolute;
  border-radius: 6px
}