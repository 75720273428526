.tabs {
    left: 64px;
    position: absolute;
}

.tabs button {
    border: none;
    text-align: center;
    cursor: pointer;
    height: 40px;
    font-size: 17px;
    padding-left: 17px;
    padding-right: 17px;
}

.tabs-button-focus:hover {
    color: white !important;
}

.tabs-button-regular:hover {
    color: white !important;
}

.tabs-button-regular:active {  
    outline: transparent;
}