.time-filter {
    font-size: 16px;
    background-color: transparent;
    border: none;
    text-align: center;
    cursor: pointer;
    height: 40px;
    margin-left: 38px;
}

.realtime-width {
    width: 67px !important;
    padding: 0;
    margin-right: 21px;
}

.historical-width {
    padding: 0;
    margin-left: 22px;
    margin-right: 10px;
    width: 308px !important; 
}

.time-filter button:hover {
    color: white;
}

.time-filter button:focus {
    border-color: transparent;
    background-color: transparent;
    color: white;
    outline: none;
}

.time-filter button:active {
    background-color: transparent;
    border-color: transparent;
    color: white;
}

.blocker {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: gray;
    overflow: auto;
    cursor: not-allowed;
    z-index: 300;
}