#map {   
    flex-grow : 1;
    position: absolute;
    top: 148px;
    right: 66px;
    left: 64px;
    bottom: 160px;     
}

/* override mapbox components style */
.mapboxgl-ctrl-logo{
    visibility: hidden !important;
}

.mapboxgl-ctrl-bottom-right {
    visibility: visible !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    visibility: hidden !important;
}

.mapboxgl-ctrl-zoom-out {
    visibility: hidden; /*TODO: remove it to show zoom-out button*/
    background: #161A1E !important;
}

.mapboxgl-ctrl-zoom-in {
    visibility: hidden; /*TODO: remove it to show zoom-in button*/
    background: #161A1E !important;
}

.mapboxgl-ctrl-group {
    background-color: #161A1E;
    box-shadow: 0 0 0 0 !important;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
    margin: 144px 0px 0px 18px;
    float: right;
    position: fixed;
    top: 16% !important;
}

.mapboxgl-ctrl-group button {
    border-color: transparent !important;
}

.mapboxgl-ctrl button:disabled .mapboxgl-ctrl-icon {
    opacity: 1 !important;
}

.mapboxgl-canvas-container.mapboxgl-interactive, .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass {
    cursor: grab;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    margin-top: -136px;
    background: transparent;
    background-color: transparent;
    border: transparent;
    pointer-events: none !important
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
    background-image: url('/images/compass-icon.svg') !important;
    width: 32px;
    height: 32px;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url('/images/zoom-in-icon.svg') !important;
    width: 48px;
    height: 48px;
    background-color: black !important;
    margin-right: 13px !important;
    position: absolute;
    right: -24px;
    top: -4px;
    border-radius: 4px;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url('/images/zoom-out-icon.svg') !important;
    width: 48px;
    height: 48px;
    background-color: black !important;
    margin-right: 13px !important;
    position: absolute;
    right: -24px;
    top: 36px;
    border-radius: 4px;
}

.trails-switch-div {
    position: fixed !important;
    right: 15px;
    top: 45% !important;
    display: grid;
    color: white;
}

.location-debug {
    color: white;
    position: absolute;
    width: 300px;
    top: 5px;
    z-index: 10;
    left: 12%;
}

.mapboxgl-canvas {
    animation-name: fade-in;
    animation-duration: 12s;
    animation-delay: -2s;
    animation-iteration-count: 1;
}

.map-cover {
    background-image: url('/images/map-loading-cover.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;   
}

@keyframes fade-in{
    0% {
        opacity: 0;
    } 
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes fade-in{ 
    0% {
        opacity: 0;
    } 
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}