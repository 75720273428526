.weather {
    position: absolute;
    width: 102px;
    min-width: 40px;
    right: 0%
}

.degrees-label {
    float: left;
    font-size: 24px;
    line-height: 28px;
    text-shadow: -2px 4px 5px rgba(0, 0, 0, 0.5);
    /* right = -15px to support fahrenheit */
    margin: 3px 5px 2px -15px;
}

.weather-icon {
    float: left;
    width: 40px;
    height: 32px;
    margin: 2px 14px 3px 0px;
}