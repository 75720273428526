.user-profile-popup {
    background-image: none;
    display: grid;
    grid-template-columns: 20px auto 47px;
    grid-template-rows: 171px auto 91px 100px;
    height: 342px;
    width: 323px;
}

.close-button {
    cursor: pointer;
    grid-column-start: 3;
    background-image: url('../../../assets/images/close-outline.svg');
    background-repeat: no-repeat;
    width: 27px;
    margin: 0px 9px 0px 0px;
}

.user-profile-content {
    font-size: 19px;
    grid-column-start: 2;
    grid-column-end: 2;
    margin-top: 32px;
}

hr {
    grid-column-start: 2;
    grid-column-end: 4;
    display: block;
    border: 0;
    border-top: 0.1vh solid #81858A;
    margin: 11px 0px 0px 0px;
    padding: 0;
    grid-row-start: 4
}

.log-out-button {
    grid-column-start: 3;
    grid-column-end: 3;
    justify-self: end;   
    font-size: 15px;
    width: 138px;
    height: 39px;
    letter-spacing: 1.5px;
    grid-row-start: 4;
    margin-top: 32px;
    color: #CED2D6;
    border-radius: 4px;
}

.grid-phase {
    margin-bottom: 19px;
}

.user-headline {
    color: #6E7276;
    font-size: 17px;
}

.user-data-text {
    font-size: 20px;
    color: #CED2D6;
}