.incident-alert-popup {
    border-radius: 5px;
    background-color: #35383C;
    align-items: center;
    z-index: 10;
    padding: 10px;
    width: 200px;
    color: #C8CCD0;
}

.popup-title {
    text-align: center;
    height: 60px;
    font-size: 14px;
}

.popup-action-button {
    color: #C8CCD0;
    background: #53565A;
    border: none;
    border-radius: 5px;
    padding: 7px 20px;
    outline: none;
    border-color: #53565A;
    border-style: solid;
    border-width: 2px;
}

.popup-action-button:hover {
    border: 2px solid #3399FF;
    outline: none;
}

.popup-action-button:active {
    background: #3399FF;
}

.popup-action-button:disabled {
    opacity: 0.5;
    border-color: rgba(83, 86, 90, .5);
}

.flex-space-around-content {
    display: flex;
    justify-content: space-around;
}